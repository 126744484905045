export const delay = (timeout) =>
  new Promise(rs =>
    setTimeout(rs, timeout));

export function forTimeout(ms, result) {
  return result ?
    ((typeof result === 'function') ?
      new Promise((rs, rj) => setTimeout(() => result(rs, rj), ms)) :
      new Promise(rs => setTimeout(() => rs(result), ms))) :
    new Promise(rs => setTimeout(rs, ms));
}

const MSEC_IN_SEC = 1000;
const MSEC_IN_MIN = MSEC_IN_SEC * 60;
Object.assign(forTimeout, {
  SEC: MSEC_IN_SEC,
  MIN: MSEC_IN_MIN
});

/**
 * Execute asynchronous function without throwing an error.
 *
 * @param { Promise<any> } promise promise to execute
 * @returns { Promise<[ Error, any ]> }
 */
export async function safelyExecuteAsync(promise) {
  try {
    return [ null, await promise ];
  } catch (ex) {
    return [ ex ];
  }
}

export function stubAsync(promise) {
  promise.then(() => void 0, () => void 0);
  return promise;
}

export function blockedAsync(asyncFn) {
  const ctx = { current: null };
  let count = 0;

  function cleanup() {
    ctx.current = null;
  }

  return (...args) => {
    if (!ctx.current) {
      ctx.current = asyncFn(...args);
      ctx.current.then(cleanup, cleanup);
    } else {
      console.log('blockedAsync blocked', count++);
    }
    return ctx.current;
  };
}

export function ExposedPromise() {
  const ctx = this;
  return Object.assign(new Promise((resolve, reject) => {
    Object.assign(ctx, { resolve, reject });
  }), ctx);
}

/**
 * Safely execute asynchronous function specified number of times or until a specified result condition is met.
 * Safely means it never throws. The result is a promise of a node-style tuple: [ error, result ]
 *
 * @param { (...any[]) => Promise<any> } asyncFn function to execute
 * @param {Object} param1
 * @param { (err: Error) => Promise<any> } param1.failAsyncFn executes when asyncFn throws an error
 * @param { (...any[]) => Promise<boolean> } param1.doWhileFn checks if result is correct and loop should finish asyncFn execution
 * @param { number } param1.retries max number of retries
 * @returns { Promise<[Error|null, any]> } Result of function execution
 */
export async function safelyAsyncRetryLoop(asyncFn, { failAsyncFn, doWhileFn, retries = 5 } = {}) {
  const [ err, result ] = await safelyExecuteAsync(asyncRetryLoop(asyncFn, { failAsyncFn, doWhileFn, retries }));
  if (err) {
    return err.hasResult ? [ err, err.result ] : [ err ];
  }
  return [ null, result ];
}

/**
 * Execute asynchronous function specified number of times or until a specified result condition is met
 *
 * @param { (...any[]) => Promise<any> } asyncFn function to execute
 * @param {Object} param1
 * @param { (err: Error) => Promise<any> } param1.failAsyncFn? executes when asyncFn throws an error
 * @param { (...any[]) => Promise<boolean> } param1.doWhileFn? checks if result is correct and loop should finish asyncFn execution
 * @param { number } param1.retries max number of retries
 * @returns { Promise.<any, Error> } Result of function execution
 */
export async function asyncRetryLoop(asyncFn, { failAsyncFn, doWhileFn, retries = 5 } = {}) {
  let result;
  let count = retries || 5;
  let err;
  let doRepeat = false;
  do {
    doRepeat = false;
    ([ err, result ] = await safelyExecuteAsync(asyncFn()));
    if (err || (doWhileFn && (doRepeat = await doWhileFn(result)))) {
      console.log('[asyncRetryLoop]', err || `doWhileFn(${ result }) is truthy, repeating`);
      failAsyncFn && await safelyExecuteAsync(failAsyncFn(err));
    }
  } while ((err || doRepeat) && (count-- > 0));
  if (err) {
    throw err;
  }
  if (doRepeat) {
    throw Object.assign(new Error('[asyncRetryLoop] result validation error'), {
      hasResult: true,
      result
    });
  }
  return result;
}

/**
 * Execute asynchronous function several times with delay.
 * Delay is increased randomly every iteration.
 *
 * @param { (...any[]) => Promise<any> } asyncFn function to execute
 * @param { ( err: Error ) => Promise<boolean> } isDelayNeededFn function which checks if delay between executions is needed
 * @param { number } firstDelay first delay in miliseconds
 * @returns
 */
export function retryLoopRepeatedly(asyncFn, isDelayNeededFn, firstDelay = 200) {
  let delayMs = firstDelay;

  return asyncRetryLoop(asyncFn, {
    failAsyncFn: async (err) => {
      if (await isDelayNeededFn(err)) {
        await delay(delayMs);
        delayMs *= (1 + Math.random());
        console.debug(`[retryLoopRepeatedly][asyncRetryLoop][failAsyncFn] after delay of ${ delayMs }ms. nextDelay is: ${ delayMs }ms`);
      }
    }
  });

}
