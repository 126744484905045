import { getLogger } from '../diagnostics';
import { safelyExecuteAsync } from '../promises';

const logger = getLogger();

export class LambdaGeneralError extends Error {}

export class LambdaError extends LambdaGeneralError {
  constructor(statusCode, arg0 = {}) {
    super();
    if (typeof arg0 === 'string') {
      Object.assign(this, { statusCode, message: arg0 });
    } else {
      Object.assign(this, arg0 || {}, { statusCode });
      if (!this.errorCode) {
        this.errorCode = statusCode;
      }
    }
  }

  static forbidden(message) {
    return new LambdaError(403, { message });
  }

  static unauthorised(message) {
    return new LambdaError(401, { message });
  }

  static notFound(message) {
    return new LambdaError(404, { message });
  }

  static badInput(message) {
    return new LambdaError(400, { message });
  }
}

/**
 *
 */
export class LambdaPayload {

  /**
   *
   * @param body
   * @param headers
   */
  constructor(body, headers, responseCode) {
    this[ Symbol.toStringTag ] = 'lambdaPayload';
    Object.assign(this, {
      body,
      ...(headers ? { headers } : {}),
      ...(responseCode ? { responseCode } : {}),
    });
  }
}


export function serveLambdaFactory({ errorMiddleware, errorMiddlewares, preProcessEventMiddleware, preProcessEventMiddlewares } = {}) {

  const processError = wrapMiddlewares([
    ...(errorMiddleware ? [ errorMiddleware ] : []),
    ...(errorMiddlewares || []),
    defaultErrorMw,
    fallbackMw
  ]);

  const preProcessEvent = wrapMiddlewares([
    ...(preProcessEventMiddleware ? [ preProcessEventMiddleware ] : []),
    ...(preProcessEventMiddlewares || []),
    (K, L) => L || K
  ]);

  return pFn => (eventInitial, context, callback) => {

    const event = preProcessEvent(eventInitial);

    const [ httpMethod, path, authorizer, Host, pathParameters ] = destructureEvent(event);
    /*
     serveLambda should on entry log:
     HTTP method
     HTTP path
     authorizer.userId
     body
     pathParameters
     */
    logger.info('Lambda on entry info', { httpMethod, path, Host, pathParameters });

    if (authorizer) {
      logger.info('Authorized', { userId: authorizer.userId, currentOrganizationId: authorizer.currentOrganizationId });
    }

    const { body, ...restEvent } = event;
    if (body) {
      logger.info('BODY', { body });
    }

    const bodyExtended = makeBody(body);
    const reconstructedEvent = Object.assign(bodyExtended, restEvent);

    const { awsRequestId: requestId } = context;
    const responseHeaders = requestId ? { requestId } : {};

    assumeAsyncExec(pFn, reconstructedEvent, context).then(result => {

        let response;
        if (result instanceof LambdaGeneralError) {
          return processError(result, responseHeaders, callback);
        } else if (result instanceof LambdaPayload) {
          response = makeLambdaResponse(result.responseCode || 200, result.body, Object.assign({}, result.headers, responseHeaders));
        } else {
          response = makeLambdaResponse(200, result, responseHeaders);
        }

        logger.info('Lambda on exit response', { response });

        callback(null, response);
      }, error => {
        console.error('serveLambda exception', error);
        logger.error('serveLambda exception', { error });

        processError(error, responseHeaders, callback);
      }
    );

    // ATTN: we do not return anything, even a Promise while using callbacks!
  };
}

async function assumeAsyncExec(fn, ...args) {
  return await fn(...args);
}

const defaultErrorMw = (error, headers, callback, next) => {
  if (error instanceof LambdaGeneralError || hasProps(error, [ 'errorCode', 'message' ])) {
    const { errorCode, statusCode, message, ...rest } = error;

    const restKeys = Object.keys(rest);
    if (restKeys.length) {
      logger.debug(`Leaving out keys ${ JSON.stringify(restKeys) }`);
    }

    const body = buildErrorBody(error);

    return callback(null, makeLambdaResponse(statusCode || errorCode, body, headers));
  }
  next();
};

const fallbackMw = (error, headers, callback) => {
  let message = 'Server error';
  if (process.env.MICROSERVICES_ASSESSMENT_ENV === 'dev') {
    message = error.message;
  }
  callback(null, makeLambdaResponse(500, buildErrorBody({ errorCode: 500, message }), headers));
};

const destructureEvent = (event) => {
  try {
    const {
      requestContext: {
        httpMethod,
        path,
        authorizer
      },
      headers: { Host },
      pathParameters
    } = event;
    return [ httpMethod, path, authorizer, Host, pathParameters ];
  }
  catch (ex) {
    return [];
  }
};

const makeBody = ((body) => {
  if (typeof body !== 'string') {
    return { body };
  }
  try {
    return {
      body: JSON.parse(body),
      bodyRaw: body
    };
  }
  catch (ex) {
    return {
      body,
      bodyRaw: body
    };
  }
});

function wrapMiddlewares(mw) {
  return mw.reduceRight((rightMw, leftMw) => (...args) => leftMw(...args, rightMw.bind(null, ...args)));
}

function hasProps(obj, propsArray) {
  return propsArray.map(p => (({}).hasOwnProperty.call(obj, p) && obj[ p ])).filter(Boolean).length === propsArray.length;
}

const defaultHeaders = {};

export const addToDefaultHeaders = (headers) => {
  Object.assign(defaultHeaders, headers);
};

export const makeLambdaResponse = (statusCode, body = {}, hh = {}) => {

  const headers = Object.assign({
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    "Access-Control-Expose-Headers": "requestid, x-amzn-requestid",
    "Access-Control-Allow-Methods": "OPTIONS,POST,PUT,DELETE,GET"
  }, defaultHeaders, hh);

  return {
    statusCode,
    headers,
    body: JSON.stringify(body)
  };
};

/**
 * Execute asynchronous function. If catches error wraps it in {@link LambdaError}
 *
 * @param { Promise<any> } promisedAction
 * @returns { Promise<any> }
 * @throws { LambdaError }
 */
export async function asyncTryCatchRethrowLambdaError(promisedAction) {
  const [ ex, result ] = await safelyExecuteAsync(promisedAction);
  if (ex) {
    logger.error(ex);
    throw new LambdaError(500, { error: ex });
  }
  return result;
}

export function buildErrorBody({ errorCode, errors, message }) {
  return {
    error: {
      errors: errors || [ { message } ],
      errorCode,
      message
    }
  };
}
