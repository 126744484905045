import { getActionTypes } from 'redux-axios-middleware';
import { getStrictlyDescription } from '../actions';
import { getIn } from '../utils';
import { ApiError, apiErrorAction } from './apiError';
import { customErrorMessage } from '../packs/alerts/actions';
import { rethrow } from './utils';
import { safelyExecuteAsync } from 'shared-package';

const localHandlers = new Map();
const localHandlerToActions = new Map();
const localHandlersOptions = new Map();

export const _internalReset = () => {
  localHandlers.clear();
  localHandlerToActions.clear();
  localHandlersOptions.clear();
};

const isProduction = (process.env.NODE_ENV === 'production');

export const makeAxiosMiddlewareOptions = (tokenAccessor, rejectOnError = false) => ({
  interceptors: {
    request: [
      ({ getState }, config) => {
        const token = tokenAccessor(getState()); //  { user: { token } } = getState();
        if (token) {
          config.headers[ 'Authorization' ] = `Bearer ${ token }`;
        }
        return config;
      }
    ],
    response: [ {
      success: function ({ getState, dispatch, getSourceAction }, res) {

        !isProduction && console.log('Axios interceptor. Response OK.', res.status, res?.config?.method, res.request.responseURL, res.data);
        return Promise.resolve(res);
      },
      error: function ({ getState, dispatch, getSourceAction }, error) {
        !isProduction && console.log('Axios interceptor. Response FAIL.',
          error?.response?.status,
          error?.config?.method,
          error?.request?.responseURL,
          error?.response?.data); // contains information about request object
        if (error?.response) {
          const { headers } = error.response;
          const [ requestId, xAmznRequestId ] = [ 'requestid', 'x-amzn-requestid' ].map(key => headers[ key ]);
          dispatch(apiErrorAction({ requestId, xAmznRequestId, error: error.toJSON() }));
          requestId ?
            dispatch(customErrorMessage({ message: `Request ID: ${ requestId }`, delay: 2 * 60 * 1000 })):
            (xAmznRequestId &&
              dispatch(customErrorMessage({ message: `X-Amazn-Request-Id: ${ xAmznRequestId }`, delay: 2 * 60 * 1000 })))
          ;
        }
        return Promise.reject(error);
      }
    } ]
  },
  onError: onErrorHandler,
  returnRejectedPromiseOnError: rejectOnError
});

function getDefaultHumanMessageHandler(humanMessage) {
  return function defaultHumanMessageHandler({ action: { payload } }) {
    if (ApiError.isApiError(payload)) {
      payload.setHumanMessage(humanMessage);
    }
  };
}

const DEBUG_TAG = Symbol('DEBUG_TAG');

export function tagWithDebug(source) {
  if (source) {
    source[ DEBUG_TAG ] = true;
  }
  return source;
}

export function isTaggedWithDebug(source) {
  return ((Object(source) === source) && (DEBUG_TAG in source));
}

export const handleErrorsFor = (actionOrActions) => (...args) => {
  const handlerRecords = ((args.length === 1) && (Array.isArray(args[ 0 ]))) ? args[ 0 ] : [ args ];
  const isDebuggerRequired = isTaggedWithDebug(actionOrActions);
  if (isDebuggerRequired) {
    debugger;
  }
  //status, fn, options
  for (const [ status, fn, options ] of handlerRecords.filter(Boolean)) {
    let effectiveFn = fn;
    if (typeof fn === 'string') {
      effectiveFn = getDefaultHumanMessageHandler(fn);
      if (isDebuggerRequired) {
        tagWithDebug(effectiveFn);
      }
    }
    handleError(status, effectiveFn, options);
    if (actionOrActions) {
      localHandlerToActions.set(effectiveFn, typeof actionOrActions === 'string' ?
        [ String(actionOrActions) ] :
        Array.from(actionOrActions, String));
    }
  }
  return actionOrActions;
};

const actionShape = {
  meta: { timestamp: Number, rest1: Object },
  payload: { request: Object },
  types: Array
};


{
  const XMLHttpRequest = (() => {
    return XMLHttpRequest;

    function XMLHttpRequest() {}
  })();

  const errorShape = {
    config: Object,
    request: XMLHttpRequest,
    response: {
      config: Object, headers: Object, request: XMLHttpRequest,
      data: {
        error: {
          errorCode: Number,
          itemSought: { Key: Object, TableName: String },
          name: 'ItemNotFoundException'
        },
        itemSought: { Key: Object, TableName: String },
        message: '',
        name: 'ItemNotFoundException'
      },
      status: Number,
      statusText: ''
    }
  };

  void actionShape;
  void errorShape;
}

const onErrorHandler = ({ action, error, next, getState, dispatch, ...etc }, options) => {
  //  const message = getIn(err, 'response.data.message', undefined);

  console.error('XHR/Fetch error', error);

  const messageStart = getStrictlyDescription(action) || '';
  const errorObject = ApiError.from(
    getIn(error, 'response.data.error', null) || error, err => {
      const { errorCode: code = getIn(err, 'response.status', 0) } = err;
      return {
        code,
        message: err.message || getIn(error, 'response.data.message', null),
        humanMessage: messageStart ? `${ messageStart } failed.` : null,
        internalError: err
      };
    });

  const baseAction = {
    type: getActionTypes(action, options)[ 2 ],
    payload: errorObject,
    error: true,
    meta: {
      previousError: error,
      previousAction: action
    }
  };

  const failAction = Object.assign({}, baseAction);

  const responseStatus = String(getIn(error, 'response.status', ''));
  const methodName = String(getIn(error, 'response.data.error.errorCode', responseStatus));
  const handler = identifyHandler(localHandlers, methodName, localHandlerToActions, action);

  let maybeReplacedAction = handler({ action: failAction, axiosAction: action, options, dispatch });

  if (typeof maybeReplacedAction === 'string') {
    failAction.payload.setHumanMessage(maybeReplacedAction);
    maybeReplacedAction = failAction;
  } else if (!maybeReplacedAction) {
    !failAction.payload.humanMessage && failAction.payload.setHumanMessage(failAction.payload.message);
    maybeReplacedAction = failAction;
  }

  return dispatch(maybeReplacedAction);
};

function identifyHandler(localHandlers, methodName, localHandlerToActions, action) {
  const [ dataSets, filters ] = [
    [
      localHandlers.has(methodName) ? localHandlers.get(methodName) : [], // specific
      localHandlers.has('*') ? localHandlers.get('*') : [] // wildcard
    ], [
      h => localHandlerToActions.has(h) && isActionWithin(action, localHandlerToActions.get(h)), // specific
      h => !localHandlerToActions.has(h) // fallback
    ]
  ];

  for (const dataset of dataSets) {
    for (const filter of filters) {
      const [ handler ] = Array.from(dataset).filter(filter);
      if (!handler) {
        continue;
      }
      return handler;
    }
  }

  return (...args) => null;
}

function isActionWithin(action, actions) {
  const { type, types } = action;
  const actionTypesSet = new Set(Array.from(actions, String));

  if (type) {
    return actionTypesSet.has(type);
  }

  for (const t of types) {
    if (actionTypesSet.has(t)) {
      return true;
    }
  }

  return false;
}

function handleError(status, fn, options) {
  insertIntoMapOfSets(localHandlers, String(status), fn);

  if (options) {
    localHandlersOptions.set(fn, options);
  }
}

function insertIntoMapOfSets(mapOfSets, key, value) {
  if (mapOfSets.has(key)) {
    const set = mapOfSets.get(key);
    if (set.has(value)) {
      return;
    }
    set.add(value);
  } else {
    mapOfSets.set(key, new Set([ value ]));
  }
}

export { ApiError };

export function unwrapData({ error, data }) {
  return error ? rethrow(error) : data;
}

export async function unwrapPayloadAndThrowIfError(promisedResult) {
  const { payload, error } = await promisedResult;
  if (error) {
    //const respError = getIn(error, 'response.data.error', undefined);
    //throw respError || error;
    throw payload || error;
    //throw (error.response && error.response.data && error.response.data.error) ? error.response.data.error : error;
  }
  return payload;
}

export async function unwrapPayloadAndErrorUnsafe(promisedResult) {
  try {
    const { payload, error } = (await promisedResult) || {};
    if (error) {
      throw payload || (error?.response?.data?.error ?? error);
    }
    return payload;
  } catch (ex) {
    try {
      const { payload, error } = ex;
      throw payload || error || ex;
    } catch (ex2) {
      throw  ex || ex2;
    }
  }
}

export async function unwrapPayloadAndError(promisedResult) {
  return safelyExecuteAsync(unwrapPayloadAndErrorUnsafe(promisedResult))
}
