import { ensureEnvVariable } from '../env';

export const HASH_ALGORITHM_VERSION = Object.freeze({
  UNIDENTIFIED: -1,
  PLAINTEXT: 0,
  BCRYPT: 1,
  SHA512: 2,
});

const envVarHashingAlgorithm = ensureEnvVariable('ACCESS_KEY_SECRET_HASHING_ALGORITHM', 'bcrypt');

export const currentHashingAlgorithm = getCurrentAlgorithmVersion(envVarHashingAlgorithm);

export function getCurrentAlgorithmVersion(envVarHashingAlgorithm) {
  return HASH_ALGORITHM_VERSION[
    (Array.from(Object.entries(HASH_ALGORITHM_VERSION)).find(([ k, v ]) => ((k === String(envVarHashingAlgorithm).toUpperCase()) || (String(v) === String(envVarHashingAlgorithm)))) || [])[ 0 ]
    ];
}
