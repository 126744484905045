import { constant, curry, safelyExecuteFnSync } from '../functional';

if (typeof btoa === 'undefined') {
  global.btoa = str => new Buffer(str, 'binary').toString('base64');
}

if (typeof atob === 'undefined') {
  global.atob = b64Encoded => new Buffer(b64Encoded, 'base64').toString('binary');
}

export function collectPathProps(obj, path) {
  if (!obj) {
    return [];
  }
  if (!path) {
    return [];
  }
  const [ firstPart, tail ] = path.split(/\.(.+)/);
  return (isObject(obj) && (firstPart in obj)) ? [ obj[ firstPart ] ].concat(collectPathProps(obj[ firstPart ], tail)) : [];
}

function isObject(subject) {
  return subject === Object(subject);
}

/**
 *
 * @description https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/pull/179/files
 * @param obj
 * @param path
 * @param defaultValue
 * @returns {*}
 */

export const getIn = (obj, path, defaultValue) => (Array.isArray(path) ? path : String.prototype.split.call(path, /[.[\]]/).filter(Boolean))
  .reduce((a, c) => (a === defaultValue) ? a : (a && Object.hasOwnProperty.call(a, c) ? a[ c ] : (defaultValue || null)), obj);

function* objPathIterator(obj, path = '') {
  if (Array.isArray(obj)) {
    for (let idx = 0, iMax = obj.length; idx < iMax; idx++) {
      yield* objPathIterator(obj[ idx ], path + '[' + idx + ']');
    }
  } else if (Object(obj) === obj) {
    for (let key of Object.keys(obj)) {
      yield* objPathIterator(obj[ key ], path ? path + '.' + key : key);
    }
  } else {
    yield [ path, obj ];
  }
}

export function* objPathNoArraysIterator(obj, path = '') {
  if (path === '' && (obj == null)) {
    // no-op
    return;
  } else if (Object(obj) === obj && Object.keys(obj).length > 0 && !Array.isArray(obj)) {
    for (let key of Object.keys(obj)) {
      yield* objPathNoArraysIterator(obj[ key ], path ? path + '.' + key : key);
    }
  } else {
    yield [ path, obj ];
  }
}

export const flattenObject = obj =>
  Array.from(objPathIterator(obj)).reduce((memo, [ k, v ]) => Object.assign(memo, { [ k ]: v }), {});

export const flattenArray = array => {
  function flattenDown(array, result) {
    for (let i = 0; i < array.length; i++) {
      const value = array[ i ];

      if (Array.isArray(value)) {
        flattenDown(value, result);
      } else if (value != null && value !== false) {
        result.push(value);
      }
    }

    return result;
  }

  return flattenDown(array, []);
};

export const flattenObjectNoArrays = obj =>
  Array.from(objPathNoArraysIterator(obj)).reduce((memo, [ k, v ]) => Object.assign(memo, { [ k ]: v }), {});

export function areObjectsDifferent(obj1, obj2) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return true;
  }
  const prev = new Map(Array.from(Object.entries(obj1)));
  for (const [ k, v ] of Object.entries(obj2)) {
    if (!prev.has(k)) {
      return true;
    }
    if (prev.get(k) !== v) {
      return true;
    }
    prev.delete(k);
  }
  return (prev.size !== 0);
}

export const payloadToStringChunksPacker = curry((propName, payload) => {
  const result = btoa(JSON.stringify(payload));
  return (result.length > 500) ?
    result.match(/.{1,500}/g).reduce((props, str, idx) => Object.assign(props, {
      [ `${ propName }${ idx }` ]: str
    }), { [ propName ]: Math.ceil(result.length / 500) }) :
    { [ propName ]: result };
});

export const payloadFromStringChunksExtractor = curry((propName, source) => {
  const { [ propName ]: initialMemoRaw, ...memos } = source;
  const memoRaw = (Number.isNaN(Number(initialMemoRaw))) ?
    initialMemoRaw :
    Array.from(Array(Number(initialMemoRaw)), (_, idx) => memos[ `${ propName }${ idx }` ]).join('');

  return safelyExecuteFnSync(JSON.parse, constant({}))(Buffer.from(memoRaw, 'base64').toString());
});

export function generalTypeShapeMatcher(object, loose, console = global.console) {
  // primitive values
  if (Object(object) !== object) {
    return function primitiveMatcher(subject) {
      return (typeof subject === typeof object) && (subject === object);
    };
  }
  if (typeof object === 'function') {
    return function constructorMatcher(subject) {
      return (subject != null) ? (subject.constructor === object) : false;
    };
  }
  // objects, arrays, functions..
  const objectKeys = Array.from(Object.keys(object));
  const keysSet = new Set(objectKeys);
  return function matcher(subject) {
    if (Object(subject) !== subject) {
      return false;
    }

    subject = JSON.parse(JSON.stringify(subject));

    if (subject.constructor !== object.constructor) {
      return false;
    }

    return objectKeys.every(objectKey => ((objectKey in subject) || (object[ objectKey ] == null)) && ((subject[ objectKey ] == null) ?
        (subject[ objectKey ] == object[ objectKey ]) :
        ((typeof object[ objectKey ] == 'function') ?
          (subject[ objectKey ].constructor === object[ objectKey ]) :
          (subject[ objectKey ] === object[ objectKey ])))) &&
      (loose ? true : Array.from(Object.keys(subject)).every(existingKey => keysSet.has(existingKey)));
  };
}

export const mapPropertiesToNumber = (obj) => {
  const newObject = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      newObject[ key ] = mapPropertiesToNumber(value);
    } else if (!Number.isNaN(Number(value))) {
      newObject[ key ] = Number(value);
    } else {
      newObject[ key ] = value;
    }
  });
  return newObject;
};
