import { LambdaLog } from 'lambda-log';
import { ensureEnvVariable } from '../env';

/**
 *
 * @returns {{error:Function, debug:Function}}
 */
const secretFields = [ 'password', 'confirmPassword', 'salt' ];
const mask = '***';

export const getLogger = (title) => {
  if (typeof window !== 'undefined') {
    return [ 'log', 'warn', 'info', 'debug', 'error' ].reduce((obj, lvl) =>
      Object.assign(obj, { [ lvl ]: (...args) => { console[ lvl ](...args); } }), {});
  }
  const log = new LambdaLog();

  const debug = ensureEnvVariable('MICROSERVICES_ASSESSMENT_DEBUG', false) === 'true';

  Object.assign(log.options, {
    debug,
    replacer
  });

  if (title) {
    log.options.tags.push(title);
  }

  return log;
};

export function replacer(key, value) {

  if (key && secretFields.includes(key)) {
    value = mask;
  } else if (key === 'body' || key === 'meta') {
    if (typeof (value) === 'string') {
      try {
        let securedBody = hideSecretFields(JSON.parse(value));
        value = JSON.stringify(securedBody);
      } catch (e) {
        // console.log('key:', key);
        // console.log('value:', value);
        // console.error('logger replacer() error:', e);
      }
    } else {
      value = hideSecretFields(value);
    }
  }

  return value;
}

function hideSecretFields(obj) {

  if (obj && typeof (obj) === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      if (typeof (obj[ key ]) === 'object') {
        acc[ key ] = hideSecretFields(obj[ key ]);
      } else if (secretFields.includes(key)) {
        acc[ key ] = obj[ key ] ? mask : obj[ key ];
      } else {
        acc[ key ] = obj[ key ];
      }

      return acc;
    }, {});
  }

  return obj;
}

const logger = getLogger();

export const logged = (...args) => {
  const [ fn, str, ...restArgs ] = args;
  if (fn instanceof Function) {
    return (...args) => {
      const [ arg0, ...rest ] = args;
      if (arg0 instanceof Error) {
        str ? logger.error(str, ...args) : logger.error(...args);
      } else {
        const arg00 = (typeof arg0 === 'object') ? JSON.stringify(arg0) : arg0;
        str ? logger.debug(str, arg00, ...rest) : logger.debug(arg00, ...rest);
      }
      return fn(...args);
    };
  }
  const arg0 = fn;
  if (arg0 instanceof Error) {
    (typeof str === 'string') ? logger.error(str, arg0, ...restArgs) : logger.error(arg0, str, ...restArgs);
  } else {
    const arg00 = (typeof arg0 === 'object') ? JSON.stringify(arg0, getCircularRefsResolver()) : arg0;
    (typeof str === 'string') ? logger.debug(str, arg00, ...restArgs) : logger.debug(arg00, str, ...restArgs);
  }
  return arg0;
};

function getCircularRefsResolver() {
  const cache = new Set();

  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Duplicate reference found, discard key
      if (cache.has(value)) return;

      // Store value in our collection
      cache.add(value);
    }
    return value;
  };
}
